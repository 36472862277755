$(document).ready(function () {
  initBooking();
  initTabLinks();
  initCustomSelect();
  initUpdatedBookingBar();
});

function initBooking() {
  $(".booking-bar__form, .booking-menu").each(function () {
    initDateSelection(this);
  });

  $(
    ".booking-bar__why-book button, .booking-bar-popup__inner .close-button, .booking-menu__why-book button"
  ).on("click", function () {
    $(".booking-menu__close").trigger("click");
    $(".booking-bar-popup").slideToggle();
  });

  $(".booking-bar__why-book button").on("click", function (e) {
    e.preventDefault(); // Stop this button from also submitting the form
  });

  if ($(".booking-bar__logo--right img").length > 0) {
    $(".booking-bar__wrapper").addClass("has-logo"); // Adds white border between booking bar and the logo, but only when the img element is there
  }
}

function formatDateForBookingEngine(date) {
  // Ensure that the date is converted to UTC and then formatted correctly
  var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  
  // Format the date to 'yyyy-mm-dd' (without time zone or time information)
  var year = utcDate.getUTCFullYear();
  var month = (utcDate.getUTCMonth() + 1).toString(); // Months are 0-based
  var day = utcDate.getUTCDate().toString();

  // Pad single-digit month and day values with leading zeroes
  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }

  return year + '-' + month + '-' + day;
}

function sendDatesToBookingEngine(checkInDate, checkOutDate) {
  // Format the dates for the booking engine (using UTC dates)
  var formattedCheckIn = formatDateForBookingEngine(checkInDate);
  var formattedCheckOut = formatDateForBookingEngine(checkOutDate);

  // Log the formatted dates for debugging
  console.log("Sending to Booking Engine - Check-in:", formattedCheckIn);
  console.log("Sending to Booking Engine - Check-out:", formattedCheckOut);

  // Send the dates to the booking engine (e.g., via AJAX, form submission, etc.)
  // Example: sendToBookingEngine(formattedCheckIn, formattedCheckOut);
}

function setDateForBlocks() {
  // Get today's date
  var today = new Date();
  
  // Get tomorrow's date for check-in (arrival)
  var tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  
  // Get the day after tomorrow's date for check-out (departure)
  var dayAfterTomorrow = new Date(tomorrow);
  dayAfterTomorrow.setDate(tomorrow.getDate() + 1);

  // Format dates for display (number and text)
  var dayForCheckIn = tomorrow.getDate(); // day of the month for check-in
  var dayForCheckOut = dayAfterTomorrow.getDate(); // day of the month for check-out
  var monthForBoth = tomorrow.toLocaleString("default", { month: "long" }); // month name for both check-in and check-out
  var yearForBoth = tomorrow.getFullYear(); // year for both check-in and check-out

  // Set check-in (arrival) div values
  document.querySelector("#check-in-block .number").innerHTML = dayForCheckIn;
  document.querySelector("#check-in-block .text").innerHTML = monthForBoth + " " + yearForBoth;
  
  // Set check-out (departure) div values
  document.querySelector("#check-out-block .number").innerHTML = dayForCheckOut;
  document.querySelector("#check-out-block .text").innerHTML = monthForBoth + " " + yearForBoth;

  // Send the dates to the booking engine (formatted without time zone)
  sendDatesToBookingEngine(tomorrow, dayAfterTomorrow);
}

// Call the function to set the dates
setDateForBlocks();
function initDateSelection(bookingContainer) {
  var checkInField = $(bookingContainer).find(".check-in");
  var checkOutField = $(bookingContainer).find(".check-out");

  var availability = $(bookingContainer)
    .find('button[type="submit"]')
    .first()
    .data("availability");

  var openingDate =
    availability && !isNaN(Date.parse(availability))
      ? new Date(availability)
      : new Date();

  // Set the time of the day to 00:00:00 for both dates to avoid time zone issues
  openingDate.setHours(0, 0, 0, 0);

  var tomorrow = new Date(openingDate);
  tomorrow.setDate(openingDate.getDate() + 1); // Add one day for arrival date
  tomorrow.setHours(0, 0, 0, 0); // Set the time to midnight for consistency

  var dayAfterTomorrow = new Date(tomorrow);
  dayAfterTomorrow.setDate(tomorrow.getDate() + 1); // Add one day for departure date
  dayAfterTomorrow.setHours(0, 0, 0, 0); // Set the time to midnight for consistency

  // Function to format date to YYYY-MM-DD format (without time zone issues)
  function formatDateToString(date) {
    var year = date.getFullYear();
    var month = pad(date.getMonth() + 1, 2); // months are 0-based
    var day = pad(date.getDate(), 2);
    return year + "-" + month + "-" + day;
  }

  // Format dates for input values
  var formattedTomorrow = formatDateToString(tomorrow);
  var formattedDayAfterTomorrow = formatDateToString(dayAfterTomorrow);

  // Set initial values in the input fields before initializing the datepicker
  $(checkInField).val(formattedTomorrow);
  $(checkOutField).val(formattedDayAfterTomorrow);

  // Initialize the datepicker for check-in and check-out fields
  $(checkInField).datepicker({
    weekStart: 1,
    format: "yyyy-mm-dd", // Set the correct date format for your environment
    autoHide: true,
    startDate: openingDate, // Make sure check-in can be any date from the openingDate onwards
    minDate: openingDate, // Enforce that users can only pick dates from today onwards
    setDate: tomorrow, // Ensure that the datepicker initializes with tomorrow's date
  });

  $(checkOutField).datepicker({
    weekStart: 1,
    format: "yyyy-mm-dd", // Set the correct date format for your environment
    autoHide: true,
    startDate: tomorrow, // Ensure check-out is always after check-in
    minDate: tomorrow, // Enforce that check-out must always be after the check-in
    setDate: dayAfterTomorrow, // Ensure that the datepicker initializes with the day after tomorrow's date
  });

  // Manually trigger setting date for both datepickers after initializing them
  $(checkInField).datepicker("setDate", tomorrow);
  $(checkOutField).datepicker("setDate", dayAfterTomorrow);

  // When the user selects a check-in date
  $(checkInField).on("change", function () {
    var checkInDate = $(checkInField).datepicker("getDate");
    var checkOutDate = $(checkOutField).datepicker("getDate");

    // Log the selected date values
    console.log("Selected Check-in Date: ", checkInDate);
    console.log("Selected Check-out Date: ", checkOutDate);

    // Ensure check-out date is always after check-in date
    if (checkInDate >= checkOutDate) {
      var dayAfterCheckIn = new Date(checkInDate);
      dayAfterCheckIn.setDate(checkInDate.getDate() + 1); // Set check-out date to be after check-in date
      $(checkOutField).datepicker("setDate", dayAfterCheckIn);
      checkOutDate = dayAfterCheckIn;
    }

    // Update the block dates
    setBlockDates(
      formatDateForDateBlocks(checkInDate),
      formatDateForDateBlocks(checkOutDate)
    );
  });

  // When the user selects a check-out date
  $(checkOutField).on("change", function () {
    var checkInDate = $(checkInField).datepicker("getDate");
    var checkOutDate = $(checkOutField).datepicker("getDate");

    // Log the selected date values
    console.log("Selected Check-in Date: ", checkInDate);
    console.log("Selected Check-out Date: ", checkOutDate);

    // Update the block dates
    setBlockDates(
      formatDateForDateBlocks(checkInDate),
      formatDateForDateBlocks(checkOutDate)
    );
  });

  $(bookingContainer).on("submit", function () {
    var checkInDate = $(checkInField).datepicker("getDate");
    var checkOutDate = $(checkOutField).datepicker("getDate");

    $(bookingContainer)
      .find('[name="arrive"]')
      .val(
        checkInDate.getFullYear() +
          "/" +
          pad(checkInDate.getMonth() + 1, 2) +
          "/" +
          pad(checkInDate.getDate(), 2)
      );
    $(bookingContainer)
      .find('[name="depart"]')
      .val(
        checkOutDate.getFullYear() +
          "/" +
          pad(checkOutDate.getMonth() + 1, 2) +
          "/" +
          pad(checkOutDate.getDate(), 2)
      );
  });
}

function formatDateForDateBlocks(date) {
  var CheckInDateNumber = date.getDate();
  var fullMonthOptions = { month: "long", year: "numeric" };
  var CheckInDateFullMonthAndYear = date.toLocaleDateString(
    "en-US",
    fullMonthOptions
  );

  return {
    CheckInDateNumber: CheckInDateNumber,
    CheckInDateFullMonthAndYear: CheckInDateFullMonthAndYear,
  };
}

function setBlockDates(checkInDate, checkOutDate) {
  var checkInNumber = checkInDate.CheckInDateNumber;
  var checkOutNumber = checkOutDate.CheckInDateNumber;

  // Add leading zero for single-digit days
  if (checkInNumber < 10) {
    checkInNumber = "0" + checkInNumber;
  }

  if (checkOutNumber < 10) {
    checkOutNumber = "0" + checkOutNumber;
  }

  // Ensure check-in block values are being set
  $("#check-in-block .number").html(checkInNumber);
  $("#check-in-block .text").html(checkInDate.CheckInDateFullMonthAndYear);

  // Ensure check-out block values are being set
  $("#check-out-block .number").html(checkOutNumber);
  $("#check-out-block .text").html(checkOutDate.CheckInDateFullMonthAndYear);
}

function pad(num, size) {
  var s = "0000" + num;
  return s.substr(s.length - size);
}
function initTabLinks() {
  $(".tabs-title a").on("click", function () {
    if ($(this).hasClass("link-not-tab")) {
      $("#" + $(this).attr("href").replace("#", ""))
        .find("a")[0]
        .click();
      return false;
    }
  });

  $('select[name="booking-select"]').on("change", function () {
    if ($(this).val() === "gift") {
      $('.booking-menu__tabs a[href="#gift"]')[0].click();
    } else if ($(this).val() === "class") {
      $('.booking-menu__tabs a[href="#class"]')[0].click();
    } else if ($(this).val().indexOf("table") > -1) {
      $('#table a[data-link="' + $(this).val() + '"]')[0].click();
    } else if ($(this).val().indexOf("spa") > -1) {
      $(".book-now").trigger("click");
      $("#spa-label").trigger("click");
    }
  });
}

function initCustomSelect() {
  var count = 0;
  $(".booking-bar__selector option").each(function () {
    if ($(this).attr("value") == "table-booking") {
      $(this).attr("value", $(this).attr("value") + "-" + count);
      count++;
    }
  });

  count = 0;
  $(".booking-menu__links a").each(function () {
    if ($(this).attr("data-link") == "table-booking") {
      $(this).attr("data-link", $(this).attr("data-link") + "-" + count);
      count++;
    }
  });

  $(".custom-select select").selectric();
}

function initUpdatedBookingBar() {
  var roomAccordion = $(".accordion.rooms");
  var guestAccordion = $(".accordion.guests");
  var roomSelectorBtn = $(".booking-rooms-summary");
  var guestSelectorBtn = $(".booking-bar-summary");

  var state = {
    isRoomActive: false,
    isGuestsActive: false,
  };

  var values = {
    rooms: 1,
    adults: 2,
    children: 0,
    maxRoom: 5,
    maxAdults: 8,
    maxChildren: 8,
    minAdults: 1,
    minRooms: 1,
  };

  var inputs = {
    rooms: $(".booking-bar-rooms"),
    adults: $(".booking-bar-adults"),
    children: $(".booking-bar-children"),
  };

  var labels = {
    rooms: $(".rooms-value"),
    totalRooms: $("#roomsTotalCount"),
    adults: $(".adults-value"),
    children: $(".children-value"),
    totalGuests: $("#guestsTotalCount"),
  };

  function setDefaultValue(number, input) {
    input.val(number);
  }

  function setAccordion(state, element) {
    if (state) {
      element.show();
    } else {
      element.hide();
    }
  }

  function handleAccordionChange(state, element1, element2) {
    if (state) {
      element1.show();
      element2.hide();
    } else {
      element1.hide();
    }
  }

  function handleClickOutSideOfTarget(e, accordion, selector, state) {
    if (
      !accordion.is(e.target) &&
      accordion.has(e.target).length === 0 &&
      !selector.is(e.target)
    ) {
      state = false;
      accordion.hide();
    }

    return;
  }

  function toggleGuestsAccordion(e) {
    state.isGuestsActive = !state.isGuestsActive;
    state.isRoomActive = false;
    handleAccordionChange(state.isGuestsActive, guestAccordion, roomAccordion);
    if (e.target.id === "booking-menu-summary") {
      $("#acc-bar-guests").css("opacity", 0);
    }
  }

  function toggleRoomAccordion(e) {
    state.isRoomActive = !state.isRoomActive;
    state.isGuestsActive = false;
    handleAccordionChange(state.isRoomActive, roomAccordion, guestAccordion);

    if (e.target.id === "booking-menu-rooms") {
      $("#acc-bar-rooms").css("opacity", 0);
    }
  }

  function appendNumOfGuests() {
    // var template = guestSelectorBtn.filter("[data-ilh-template]").data("ilhTemplate").replace("#", (values.adults + values.children));
    // guestSelectorBtn.val(template)
    labels.totalGuests.html(values.adults + values.children);
  }

  function appendNumOfRooms() {
    // var template = roomSelectorBtn.filter("[data-ilh-template]").data("ilhTemplate").replace("#", values.rooms);
    // roomSelectorBtn.val(template)
  }

  function setData(value, targetInput, targetLabels) {
    targetInput.val(value);

    targetLabels.forEach(function (targetLabel) {
      targetLabel.html(value);
    });
  }

  function incrementRooms() {
    if (values.rooms < values.maxRoom) {
      values.rooms++;
      values.totalRooms++;

      var hiddenInput = document.getElementById("roomsHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue + 1;
    }
    setData(values.rooms, inputs.rooms, [labels.rooms, labels.totalRooms]);
    appendNumOfRooms();
  }

  function decrementRooms() {
    if (values.rooms > values.minRooms) {
      values.rooms--;
      values.totalRooms--;

      var hiddenInput = document.getElementById("roomsHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue - 1;
    }
    setData(values.rooms, inputs.rooms, [labels.rooms, labels.totalRooms]);
    appendNumOfRooms();
  }

  function incrementAdults() {
    if (values.adults < values.maxAdults) {
      values.adults++;
      values.totalGuests++;

      var hiddenInput = document.getElementById("adultHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue + 1;
    }

    setData(values.adults, inputs.adults, [labels.adults, labels.totalGuests]);
    appendNumOfGuests();
  }

  function decrementAdults() {
    if (values.adults > values.minAdults) {
      values.adults--;
      values.totalGuests--;

      var hiddenInput = document.getElementById("adultHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue - 1;
    }
    setData(values.adults, inputs.adults, [labels.adults, labels.totalGuests]);
    appendNumOfGuests();
  }

  function incrementChildren() {
    if (values.children < values.maxChildren) {
      values.children++;
      values.totalGuests++;

      var hiddenInput = document.getElementById("childHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue + 1;
    }
    setData(values.children, inputs.children, [
      labels.children,
      labels.totalGuests,
    ]);
    appendNumOfGuests();
  }

  function decrementChildren() {
    if (values.children > 0) {
      values.children--;
      values.totalGuests--;

      var hiddenInput = document.getElementById("childHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue - 1;
    }
    setData(values.children, inputs.children, [
      labels.children,
      labels.totalGuests,
    ]);
    appendNumOfGuests();
  }

  setDefaultValue(2, inputs.adults);
  setDefaultValue(0, inputs.children);
  setDefaultValue(1, inputs.rooms);
  setAccordion(state.isRoomActive, roomAccordion);
  setAccordion(state.isGuestsActive, guestAccordion);

  guestSelectorBtn.on("click", function (e) {
    toggleGuestsAccordion(e);
    appendNumOfGuests();
  });
  roomSelectorBtn.on("click", function (e) {
    toggleRoomAccordion(e);
  });
  $(".booking-bar").on("click", function (e) {
    handleClickOutSideOfTarget(
      e,
      roomAccordion,
      roomSelectorBtn,
      state.isRoomActive
    );
    handleClickOutSideOfTarget(
      e,
      guestAccordion,
      guestSelectorBtn,
      state.isGuestsActive
    );
  });
  $(".booking-menu").on("click", function (e) {
    handleClickOutSideOfTarget(
      e,
      roomAccordion,
      roomSelectorBtn,
      state.isRoomActive
    );
    handleClickOutSideOfTarget(
      e,
      guestAccordion,
      guestSelectorBtn,
      state.isGuestsActive
    );
  });
  $(".increment-rooms").on("click", incrementRooms);
  $(".decrement-rooms").on("click", decrementRooms);
  $(".increment-adults").on("click", incrementAdults);
  $(".decrement-adults").on("click", decrementAdults);
  $(".increment-children").on("click", incrementChildren);
  $(".decrement-children").on("click", decrementChildren);

  $(".booking-menu__close").on("click", function () {
    $("#acc-bar-rooms").css("opacity", 1);
    $("#acc-bar-guests").css("opacity", 1);
  });
}
